export default [
  {
    id: '1',
    title: '医药生产',
    descript: '生产物流与流通物流软硬件一体集成方案',
    image: '51',
    detail: {
      banner: '27',
      defect: {
        title: '行业痛点',
        show: true,
        descript: [
          '上下游信息传递，上下游无法准确获取订单相关状态信息，无法通过随货同行标签或单据提取信息来简化流程，提高作业效率',
          '质量管理涉及资质，资料及库存、批次、质量检验等，包含作业流程过程中的交接管理与差错控制。保证质量过程数据完整性和一致性，满足GSP标准，全链可追溯、主动防范的工作复杂度高，工作量大',
          '原料、成品、资材仓储管理流程与质量管理流程各不相同，需要协同原料、包材到生产供给，协同生产下线到半成品，成品的存储，协同成品的仓储出库与运输配送。各流程同步性控制难度大',
          '周期性统计数据从分散各个系统中提取数据，各环节层层上报进行汇总统计，工作量大，数据实时性与真实性无法保证，无法实现以数字支撑决策，存在管理时效滞后的问题',
          '在原料与物流分类的管理中作业专业性强，对作业人员依赖性强，标准化程度不足，差错率与效率因人而异。上下游作业过程上稳定能力匹配得不到保证'
        ]
      },
      solution: {
        title: '解决方案概述',
        show: true,
        descript: [
          '数字化实时管控，标准化流程管理，全链化质量监管，智能化作业赋能',
          '物流平台连通上下游、连通车间、仓库，数据共享融合，实现精益的计划控制与科学任务调度与控制协同，全过程实时透明，一码流通溯源的数字化实时管控',
          '从采购到成品出库标准化作业流程管理，全系统应用覆盖，标准化、精细化分类管理',
          '质量管理与作业流程融合，质量管理任务实时生成，全链路形成闭环系统主动稽核',
          '使用立体库，穿梭车、AGV，输送分拣，自动驾驶、机械臂、手持终端等智能装备，实现园区高效率，高准确性，高吞吐量的作业能力'
        ]
      },
      advantage: {
        title: '方案优势',
        descript: [
          {
            icon: '10',
            title: '生产、流通物流一套系统集成管理',
            descript: '一套平台满足原料，资材，成品的分类与协同管理，适配生产、流通多种物流模式。从上下游客户连接与服务、运营管理、仓储与运输配送作业全流程一套系统应用覆盖'
          },
          {
            icon: '7',
            title: '数据实时集中全景数字化管控',
            descript: '提供一套数据平台底座，集中业务数据，物流过程数据与物联数据。应用资源、流量、压力、效率、时效、成本费用等分析模型来一屏总揽物流运营状态，自动统计分析，进行智能任务调度与控制各环节协同，监控预警与主动发现异常'
          },
          {
            icon: '9',
            title: '质量管理与作业流程融合全过程闭环监控',
            descript: '全生命周期质量管理，伴随业务与生产、物流过程，以配置的质量规范生成质量管理数据与质量管理任务，形成上下游环节自动审计与全链路闭环监管，一码流通，全程追溯'
          },
          {
            icon: '8',
            title: '高度智能化装备实现高效率与高吞吐作业',
            descript: '与作业流程、流量匹配的智能化的装备应用，实现园区入库，出库、集货、转运、送料等多个场景的无人化。实现作业的标准化，高效率，高准确性、与高吞吐量与各环节间通畅衔接，以及高密度存储和高库容利用率'
          }
        ]
      },
      partner: {
        title: '合作客户',
        images: [17, 12, 10, 11]
      }
    }
  },
  {
    id: '2',
    title: '医药流通',
    descript: '多仓多货主平台化模式，兼容多业态应用场景',
    image: '46',
    detail: {
      banner: '28',
      defect: {
        title: '行业痛点',
        show: true,
        descript: [
          '上下游订单相关数据未共享，无法通过随货同行标签或单据提取信息来简化 流程，提高作业效率',
          '服务对象类型多，涉及药品批发企业、连锁药店、单体药店、生产企业、医院、诊所、社区。业态类型多：批发、电商、连锁、互联网医院、三方，服务需求多样化，很难全面兼顾',
          'SKU种类数量偏高，订单碎片化，需求越来越驱向零散，拆零分拣量大，业务趋向终端，要求时效高',
          '客户下单少有计划性，易出 现峰值，造成物流作业忙闲程度不均，很难通过计划性统筹调度，降低成本， 提高分拣与配送效率',
          '在传统的西药、中药、器械、计生业务基础上，冷链药品、食品生鲜、消费口、等品类日益增多。各品类存储及作业流程、质量管理需求差异大，各业 态融合作业难度高',
          '同时兼具B端和C端客户订单特性，订单行、品规数、单品订单量、包装 要求、监管要求、票据要求、配送需求不一;难以用一种存储或拣选及配送模式，低成本适配所有客户需求'
        ]
      },
      solution: {
        title: '解决方案概述',
        show: true,
        descript: [
          '全流程智能装备配套，高度自动化生产赋能、一体多应用场景集成、柔性的设计实现能力的扩展，保障作业效率与吞吐量',
          '上线实现智慧物流管理平台，上下游互联互通、标准运营、精益管理、智慧调度、全流程协同',
          '业务中台化，配置化参数、流程与二次开发工具、实现多物流模式深度适配，并具有高度扩展性',
          '数据中台化，多维数据集中，应用资源、压力、质量、效率、时效、安全、成本费用模型融合计算，实现管理可视化监管，数据驱动决策'
        ]
      },
      advantage: {
        title: '方案优势',
        descript: [
          {
            icon: '11',
            title: '全方位GSP质量管理体系',
            descript: '从到货验收、特殊商品拣选、复核、在库养护等作业流程配套全环节质量流程。保障质量过程数据及时性、一致性、完整性。温湿度、近效期等质量风险实时检测可预警'
          },
          {
            icon: '12',
            title: '多仓多货主平台化，兼容多业态应用场景',
            descript: '多仓、多货主，多业态场景的平台模式，支持快速开仓，多仓联动、仓配联动的多主体作业协同，支持操作维度、管理维度支持无限递归的层级功能与数据授权，作业模式可选、参数可设定、策略可配置、流程可组装，平台可自定义开发扩展。一体化兼容TO B、C、F、M的任业场景'
          },
          {
            icon: '13',
            title: '柔性智能作业场景',
            descript: '插件式适配立体库、穿梭车、AGV、机械臂、箱式立库、拣选机器人、输送分拣、称重复核、手持终端、电子标签、RFID、AGS翻盖等作业设备。与业态、作业模式和流程柔性配套。具有高效率、高准确、高扩展与高投资回报特性'
          },
          {
            icon: '8',
            title: '智能调度与同步性控制',
            descript: '可按货主行业属性、地理位置、时效、包装、交接要求等属性配置库存、路由、仓储作业模式、配送方式、单证格式等策略。智能计划管理，控制多主体多任务顺序执行，控制资源与任务波次的配给，控制上下作业环节流量匹配，控制任务的优化级，控制仓储与配送作业同步。实现资源最大化利用与大规模吞吐量'
          }
        ]
      },
      partner: {
        title: '合作客户',
        images: [5, 18, 3, 14]
      }
    }
  },
  {
    id: '3',
    title: '医药连锁',
    descript: '多主体信息无缝集成，高并发作业与高效率流通',
    image: '47',
    detail: {
      banner: '29',
      defect: {
        title: '行业痛点',
        show: true,
        descript: [
          '上下游信息数据没有贯通，无法及时获取状态信息，无法根据标识提取信息，准确与高效作业。随货同行资料多样，工作量大，归档完整性无法保证',
          '订单拆零量较大，零货周转率高，差错较大，整件向拆领取补货量需求大，出库和补货及时性要求高；零货打包复核效率低，影响订单集货同步性',
          '医药连锁行业客户要求配送趋于及时配送，平衡配送时效、成本与车辆装载率，以及的退换货和容器回收需要一体化调度，对调度人员严重依赖',
          '节假日、淡旺季、业务活动业务量差异大，峰值集中，需要提前进行品种，库存、资源运营计划管理。仓储作业模式需具有高柔性、对运营动态调度资源并保障上下游作业的过程一体协同和流量控制要求高',
          '物流成本费用核算模型复杂，分摊规则难以确定，数据完整性要求高，统计分析维度要求灵活，实现精细化核算物流成本费用及利润难度大'
        ]
      },
      solution: {
        title: '解决方案概述',
        show: true,
        descript: [
          '物流平台连通上下游、与上游供应商系统无缝对接、从门店请货到供应商发货供应链全环节打通',
          '标准化作业流程管理，从订单接收到交接签收全流程应用覆盖，订单全生命周期的实时跟踪，精细物流费用核算、全可视化运营管理',
          '质量管控及商品品质保障与作业流程融合，主动预警与防范，全链路闭环系统主动稽核',
          '平台数智化调度，计划执行提醒、补货任务生成，自动集单，任务打包，装载计算与路径规划，仓配任务同步性控制等降低人工依赖，降本增效',
          '物流中心使用多料箱AGV、货到人AGV、电子标签、PDA、内复核分拣机、交叉带分拣机等智能装备，实现物流作业的便捷性，低差错、高效率'
        ]
      },
      advantage: {
        title: '方案优势',
        descript: [
          {
            icon: '10',
            title: '仓配一体化协同管理',
            descript: '平台对接上游供应商系统，增设下游门店签收管理，实现打通上下游供应链环节。从上下游客户连接与服务、运营管理、仓储与运输配送作业过程精细管理，一体协同'
          },
          {
            icon: '9',
            title: '数据集中，实时融合运算，全程可视化',
            descript: '集中业务数据，物流过程数据与物联数据，实时共享，应用资源、流量、压力、效率、时效、成本费用等分析模型来一屏总揽物流运营状态，自动统计分析，进行智能任务调度与控制各环节作业协同，监控预警与主动发现异常'
          },
          {
            icon: '14',
            title: '流程融合，全过程闭环管理',
            descript: '全生命周期质量管理，伴随业务与源产采集、物流过程，前后环节共享质量信息，作业过程中质量及商品品控相关流程衔接。质量问题及时拦截，品控风险定时检测，消息预警实时通知，运营管理及时干预'
          },
          {
            icon: '15',
            title: '高度智能化装备具备柔性的峰值应对能力',
            descript: '与作业流程、流量匹配智能化的装备应用，实现供应商发货、配送、质检、分装、拣货、打包复核、集货、装车等多个场景的自动化、无人化。实现作业的标准化，高效率，高准确性、以及高密度存储和高库容利用率'
          },
          {
            icon: '13',
            title: '时效性与准确性提高',
            descript: '计划控制，计划执行提醒、补货任务生成，自动集单，任务打包，装载计算与路径规划， 仓配任务同步性控制，承运主体推荐等降低人工依赖，时效性与准确性提高。充分发挥单次规模效益降低成本'
          }
        ]
      },
      partner: {
        title: '合作客户',
        images: [13, 16, 1, 20]
      }
    }
  },
  {
    id: '4',
    title: '医疗器械',
    descript: '一码流通，全生命周期物流过程管理应用覆盖',
    image: '48',
    detail: {
      banner: '30',
      defect: {
        title: '行业痛点',
        show: true,
        descript: [
          '医疗器械行业包含直销、分销、零售、电商、工厂直发、集中配送、产品配送、医疗服务等行业全业态，物流需匹配医疗器械行业多种业态，满足各业态作业模式',
          '医疗器械同一成品规格较多，外观不易识别，入出库作业差错率高；需对常规仓、寄售点仓、借出仓等多种类型库存管理，以及各仓库间的库存实时联动，库存管理难度很大',
          '生产到使用环节需要全生命周期追溯；需实现全程跟踪至用户手术跟台使用、转台使用、使用退回等全业务闭环追溯',
          '要求仓储作业人员需熟悉医疗器械性能及质量管控要求，对专业度要求较高；仓储入出库作业效率低',
          '医疗器械行业主要客户群体有：医院、药店、个人用户等，其中医院客户占比较多；医院客户对物流交付及时性要求较高，各大医院对物流交付的要求不一致，同时需满足针对特定的客户提供特定的增值服务'
        ]
      },
      solution: {
        title: '解决方案概述',
        show: true,
        descript: [
          '物流系统兼容医疗器械行业全业态流程，包括常规、临调、寄售、钉盒、BOM模式等行业特殊作业模式',
          '与客户系统全渠道化订单无缝对接，实现从生产到使用环节全生命周期的可视化追溯',
          '多仓多业主精细化货位管理，多仓协同联动，全局供应链库存实时可视与智能补货',
          '匹配AGV、机械手、立体仓库等先进智能设备，提高仓储作业效率、降低人员专业度要求、减少差错',
          '精益的分级的计划管理，仓配一体化同步性控制、配送全流程应用覆盖，实时时效与质量监管，保证订单履约能力'
        ]
      },
      advantage: {
        title: '方案优势',
        descript: [
          {
            icon: '16',
            title: '支持一码流通全生命周期质量管理',
            descript: '物流系统兼容医疗器械品种全部编码主数据，实现从生产、流通到产品使用环节全生命周期追溯，为产品使用提供质量保障'
          },
          {
            icon: '12',
            title: '精细化货位库存管理及库存转换',
            descript: '提供常规仓、寄售点仓、借出仓等多种类型库存管理，实现货位、批号、批次、库存状态级精细化库存管理与库存联动转换'
          },
          {
            icon: '6',
            title: '支持多仓、多业主管理，快速开仓、多仓联动',
            descript: '支持多仓、多业主管理，实现快速开设分仓、实现主仓与寄售点仓仓间联动调度管理'
          },
          {
            icon: '8',
            title: '支持多业态、多物流作业模式',
            descript: '平台以多方式接入订单，与产品规则自动匹配，根据业务规则自动折合单，实时线路班次与计划执行提醒，提高订单的时效履约率。智慧路由、资源分配、装载与路径规划等降低调度工作难度，发挥规模效益，节省成本'
          },
          {
            icon: '9',
            title: '智能装备实现高效率、高准确性、 高吞吐量的物流作业',
            descript: '实施与模式和流程对应的立体库、穿梭车、AGV,拣选机器人，分拣输送智能装备保证吞吐量，准确性与高效率。应用北斗/GSP, ADS主动安全，温湿度监控等物联装备实时运行状态，保障运输过程中质量与安全'
          },
          {
            icon: '4',
            title: '全环节物流时效管控',
            descript: '计划分级管控、仓配一体协同、全环节物流时效预警监控'
          }
        ]
      },
      partner: {
        title: '合作客户',
        images: [7, 8, 4, 9]
      }
    }
  },
  {
    id: '5',
    title: '食品生鲜',
    descript: '从田间到舌间的完整供应链平台解决方案',
    image: '49',
    detail: {
      banner: '31',
      defect: {
        title: '行业痛点',
        show: true,
        descript: [
          '上下游信息传递，上下游无法准确获取订单需求信息，无法向下传递检验检疫及相关资质、报告信息，无法通过随货同行标签或单据提取信息来简化流程，提高作业效率',
          '生鲜商品大都要求即时配送，从源采到末端配送过程中执行主体多，涉及商品品质保障与相关检验检疫报告数据共享与管理交接难度高。供应商资质、效期等更新频繁，商品有效期短，各环节质量风险高',
          '商品品类繁多，涉及干货、冻货、果蔬，加工、肉类、海产品等，各品类商品存储要求，出入库及配送作业流程差异大，按件、瓶、套、捆、板、克、千克等多计量单位管理， 人为差错率高，设施设备及人员利用率低',
          '商品存储及运输过程中商品自然损耗，净菜加工、生加工等作业流程损耗，异常损耗等难以精准定位，损耗节点溯源困难',
          '在生鲜类商品存储及运输过程中为保证商品品质与质量对包装材料、温湿度要求高，因此存储及配送成本高'
        ]
      },
      solution: {
        title: '解决方案概述',
        show: true,
        descript: [
          '数字化实时管控，一体化协同运营，全链化品控保障，智能化作业赋能',
          '实施物流平台连通上下游、供应链全过程线上管理，全链作业一体化协同运营，从田园到舌尖全链数字化实时管控',
          '从下游请货到上游备货标准化作业流程管理，全系统应用覆盖，标准化、精细化分类管理，减少商品滞留和损耗',
          '质量管控及商品品质保障与作业流程融合，质量问题及时拦截，品质保障实时检测及时预警，及时通知，及时干预与主动稽核',
          '使用冷库穿梭车、托盘穿梭车、多温层AGV、自动电子秤、手持终端等智能装备，实现物流作业的便捷性，低差错、高效率'
        ]
      },
      advantage: {
        title: '方案优势',
        descript: [
          {
            icon: '10',
            title: '上下游业务信息联动、仓配一体化协同管理',
            descript: '一套平台满足下游配餐、请货，集中采配调度、上游供应商执行多种运作主体间协作。从上下游客户连接与服务、运营管理、仓储与运输配送作业过程精细管理，一体协同'
          },
          {
            icon: '3',
            title: '数据实时共享，全景数字化管控',
            descript: '集中业务数据，物流过程数据与物联数据，实时共享，应用资源、流量、压力、效率、时效、成本费用等分析模型来一屏总揽物流运营状态，自动统计分析，进行智能任务调度与控制各环节作业协同，监控预警与主动发现异常'
          },
          {
            icon: '2',
            title: '全过程闭环监控',
            descript: '全生命周期质量管理，伴随业务与源产采集、物流过程，前后环节共享质量信息，作业过程中质量及商品品控相关流程衔接。质量问题及时拦截，品控风险定时检测，消息预警实时通知，运营管理及时干预'
          },
          {
            icon: '1',
            title: '高度智能化装备实现高效率与高吞吐作业',
            descript: '与作业流程、流量匹配的智能化的装备应用，实现源产收购、供应商备货、 配送、质检、分装等多个场景的自动化、无人化。实现作业的标准化，高效 率，高准确性、以及高密度存储和高库容利用率'
          }
        ]
      },
      partner: {
        title: '合作客户',
        images: [2, 6, 15, 19]
      }
    }
  },
  {
    id: '6',
    title: '三方物流',
    descript: '实现一体协同的物流网络，端到端运作能力',
    image: '50',
    detail: {
      banner: '32',
      defect: {
        title: '行业痛点',
        show: true,
        descript: [
          '上下游客户众多，系统类型多，传输协议、数据格式与标准不统一，实现客户快速对接，及时接收与反馈数据，实时透明可视可追溯难度大，工作量大',
          '客户服务的要求多样化，订单流量不均，客户地理分布随机，计划管理、路由调度，装载与路径规划实现发挥规模效率，降低运作成本，人工达到全局考虑，调度难度大',
          '运作过程涉及多主体协同作业，需要掌握各主体资源，流量，效率、质量、安全状态，订单、过程、物联数据融合运算，实现全景监管与主动防范要求，需要一套完整的大数据体系来提供支撑',
          '客户的业态批发，电商、连锁，零售等业态，存储运输要求多样，订单结构不一致，流量不均衡，一个运作主体有不同作业模式来进行适配',
          '三方物流成本费用核算模型复杂，分摊规则难以确定，数据完整性要求高，统计分析维度要求灵活，实现精细化核算物流成本费用及利润目标难度大'
        ]
      },
      solution: {
        title: '解决方案概述',
        show: true,
        descript: [
          '实施云智物流平台三方物流解决方案，以集成系统高度可配置化发布与接入数据，与客户系统无缝对接',
          '以业务管理系统进行物流资源、网络构建与产品服务管理，计划管控，智能的路由调度、任务调度与多主体的协同控制',
          '以计费管理产品定价，合同协议与核算，核准，对帐、结算、开票、与核销的过程',
          '以仓储系统来满足电商、连锁、批发、零售、中转不同的业态的To B、C、F的仓储作业模式',
          '以配送系统实现运力管理，揽、集、干线、支线、散、配送、交付、回单多任务协同的全流程管理',
          '匹配与流程对应的立体库、穿梭车、AGV,拣选机器人，分拣输送智能装备保证仓储作业吞吐量，准确性与高效率'
        ]
      },
      advantage: {
        title: '方案优势',
        descript: [
          {
            icon: '10',
            title: '上下游互联互通,全过程透明服务体验',
            descript: '集成平台对接上下游系统，物流订单流程、流通过程、质量、时效与地理信置、异常、帐单实时数据反馈与主动通知，WEB、微信、APP的信息获取与客服。实现全过程透明服务体验'
          },
          {
            icon: '7',
            title: '全流程应用覆盖与全景数字化运营',
            descript: '台中的物流门户，订单管理、任务调度、计费管理，仓储管理、运配管理、运营分析等系统覆盖全流程的应用场景。数据中台汇集数据，融合运算和规则与指标比对分析，实时可视状态审计发现异常、主动防范风险'
          },
          {
            icon: '17',
            title: '构建多层次物流网络与一体化协同运作',
            descript: '集中管理仓储、运力、库存资源，构建平台、枢纽、网点等多层次的物流网络，平台协同内外部多个运作主体，实现多仓联动、多段联运与平台化模式运作，提升整体物流运作能力'
          },
          {
            icon: '8',
            title: '智能计划管理与任务调度',
            descript: '平台以多方式接入订单，与产品规则自动匹配，根据业务规则自动折合单，实时线路班次与计划执行提醒，提高订单的时效履约率。智慧路由、资源分配、装载与路径规划等降低调度工作难度，发挥规模效益，节省成本'
          },
          {
            icon: '18',
            title: '智能装备赋能,物流高效安全作业',
            descript: '实施与模式和流程对应的立体库、穿梭车、AGV,拣选机器人，分拣输送智能装备保证吞吐量，准确性与高效率。应用北斗/GSP, ADS主动安全，温湿度监控等物联装备实时运行状态，保障运输过程中质量与安全'
          },
          {
            icon: '19',
            title: '精益的成本核算与费用管理',
            descript: '应用作业成本法模型，以最小动作单元核算成本。从产品定价、合同协议、费用核算，对帐，结算，核销全流程费用管理。以经营分析模型自动生成报表，指导产品、资源布局与经营策略调整'
          }
        ]
      },
      partner: {
        title: '合作客户',
        images: [5, 18, 3, 14]
      }
    }
  }
]